<template>
  <div>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-img :src="url" aspect-ratio="1.5" class="grey lighten-2">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute>
              <v-btn @click="changeImage()">Change Image</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-img>
      </template>
    </v-hover>
    <PropertyImageDialog
      :property="property"
      @image-updated="imageUpdated"
      ref="propertyImageDialog"
    ></PropertyImageDialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import Storage from "@aws-amplify/storage";
import PropertyImageDialog from "./PropertyImageDialog";

export default {
  name: "PropertyImages",
  components: {
    PropertyImageDialog,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: "",
    };
  },
  methods: {
    async loadImages() {
      var image = this.property.primaryImage
        ? this.property.primaryImage
        : "defaultPrimaryImage.jpg";
      this.url = await Storage.get(image, {});
    },
    changeImage() {
      this.$refs.propertyImageDialog.changeImage();
    },
    imageUpdated(filename) {
      this.property.primaryImage = filename;
      this.$emit("success", "Image Updated");
      this.loadImages();
    },
  },
  mounted() {
    this.loadImages();
  },
};
</script>