<template>
  <v-container fluid v-if="loaded == true">
    <v-breadcrumbs :items="breadcrumbItems" light></v-breadcrumbs>
    <PropertyComplianceAlert :property="property"></PropertyComplianceAlert>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="blue darken-3" dark>
            <v-toolbar-title>Property Details</v-toolbar-title>
          </v-toolbar>
          <v-container no-gutters>
            <v-row no-gutters>
              <v-col cols="12" sm="7">
                <PropertyImages
                  :property="property"
                  @success="$emit('success', $event)"
                ></PropertyImages>
              </v-col>
              <v-col cols="12" sm="5">
                <v-card-text>
                  <div class="text-subtitle-1">{{ property.address.line }}</div>
                  <div v-if="property.contact" class="text-subtitle-1">
                    <v-icon>mdi-account</v-icon>
                    &nbsp;
                    {{ property.contact }}
                  </div>
                  <div v-if="property.phoneNumber" class="subtitle-1">
                    <v-icon>mdi-phone</v-icon>
                    &nbsp;
                    <a :href="telephoneLink">{{ property.phoneNumber }}</a>
                  </div>
                  <div v-if="property.email" class="subtitle-1">
                    <v-icon>mdi-email</v-icon>
                    &nbsp;
                    <a :href="emailLink">
                      {{ property.email }}
                    </a>
                  </div>
                  <div class="float-right">
                    <v-btn class="ma-2" text icon @click="copyToClipboard" v-if="property.contact">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PropertyCompliancePanel
          :property="property"
          @success="$emit('success', $event)"
        ></PropertyCompliancePanel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PropertyRemindersPanel
          :property="property"
          @success="$emit('success', $event)"
        ></PropertyRemindersPanel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PropertyAttachmentsPanel
          :property="property"
        ></PropertyAttachmentsPanel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PropertyTenantsPanel
          :property="property"
          @success="
            $emit('success', $event);
            loadProperty();
          "
        ></PropertyTenantsPanel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PropertyLogPanel
          :property="property"
          @success="$emit('success', $event)"
        ></PropertyLogPanel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PropertyJobsPanel
          :property="property"
          @success="$emit('success', $event)"
        ></PropertyJobsPanel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import PropertyRemindersPanel from "./PropertyRemindersPanel";
import PropertyTenantsPanel from "./PropertyTenantsPanel";
import PropertyAttachmentsPanel from "./PropertyAttachmentsPanel";
import PropertyImages from "./PropertyImages";
import PropertyJobsPanel from "./PropertyJobsPanel";
import PropertyComplianceAlert from "./PropertyComplianceAlert";
import PropertyCompliancePanel from "./PropertyCompliancePanel";
import PropertyLogPanel from "./PropertyLogPanel";
import copy from "copy-to-clipboard";
export default {
  name: "PropertyDetails",
  components: {
    PropertyRemindersPanel,
    PropertyTenantsPanel,
    PropertyAttachmentsPanel,
    PropertyImages,
    PropertyJobsPanel,
    PropertyComplianceAlert,
    PropertyCompliancePanel,
    PropertyLogPanel,
  },
  computed: {
    telephoneLink() {
      return "tel:" + this.property.phoneNumber;
    },
    emailLink() {
      return "mail:" + this.property.email;
    },
  },
  data: () => ({
    loaded: false,
    breadcrumbItems: [],
    property: null,
  }),
  methods: {
    loadProperty() {
      API.get("sites", "/sites/" + this.$route.params.id, {})
        .then((response) => {
          this.property = response;
          this.loaded = true;
          this.breadcrumbItems = [
            {
              text: "Properties",
              disabled: false,
              to: "/properties",
            },
            {
              text: this.property.address.address,
              disabled: true,
            },
          ];
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.response);
        });
    },
    copyToClipboard() {
      var str = `
${this.property.address.line}
${this.property.contact}
${this.property.phoneNumber}
${this.property.email}`;
      copy(str);
    },
  },
  mounted() {
    this.loadProperty();
  },
};
</script>

<style scoped>
.first-panel {
  padding-top: 0px;
}

#property-details-table td {
  vertical-align: top;
}

#property-details-table td.header {
  font-weight: bold;
}
</style>