<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tenants"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:item.startDate="{ item }">
        {{ item.startDate | moment("Do MMMM YYYY") }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ item.endDate | moment("Do MMMM YYYY") }}
      </template>
      <template v-slot:item.rent="{ item }"> 
        {{ formatRent(item.rent) }} 
      </template>
    </v-data-table>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import store from "@/store";

export default {
  name: "TenantList",
  data: () => ({
    tenants: [],
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Property",
        value: "site.address.line",
      },
      {
        text: "Telephone",
        value: "phone",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Start Date",
        value: "startDate",
      },
      {
        text: "End Date",
        value: "endDate",
      },
      {
        text: "Rent",
        value: "rent",
      },
    ],
  }),

  methods: {
    async getTenants() {
      try {
        let response = await API.get(
          "customers",
          "/customers/" + store.getters.engineerManagerId + "/tenants",
          {}
        );
        this.tenants = response;
        this.loaded = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // you can handle different errors differently
        // or just display an error message instead of your table using a <v-if> tag
        // or navigate to an error page
      }
    },
    formatRent(value) {
      let val = (value / 1).toFixed(2);
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  mounted() {
    this.getTenants();
  },
};
</script>
