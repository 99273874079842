<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <v-card>
      <v-card-title class="headline">Renew Reminder</v-card-title>
      <v-container grid-list-sm>
        <v-form ref="reminderForm" v-model="valid">
          <v-layout row wrap>
            <v-flex xs12>
              <v-menu
                v-model="dueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dueDate"
                    label="Reminder Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    :rules="[rules.required]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dueDate"
                  @input="dueDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="save()" :disabled="!valid"
          >Save</v-btn
        >
        <v-btn text @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import moment from "moment";
export default {
  name: "RenewalDialog",
  methods: {
    renewReminder(reminder) {
      this.reminder = reminder;
      this.showDialog = true;
    },
    save() {
      if (this.valid) {
        let newReminder = {};
        // Convert dates from String to Epoc
        newReminder.dueDate = moment
          .utc(this.dueDate, "YYYY-MM-DD")
          .startOf("day")
          .valueOf();
        newReminder.type = this.reminder.type;
        API.post("sites", "/sites/" + this.reminder.site.id + "/reminders/", {
          body: newReminder,
        }).then(() => {
          this.showDialog = false;
          this.$refs.reminderForm.reset();
          this.$emit("reminder-renewed");
        });
      }
    },
    cancel() {
      this.showDialog = false;
      this.$refs.reminderForm.reset();
      this.reminder = {};
    },
  },
  data: () => ({
    showDialog: false,
    valid: false,
    dueDateMenu: false,
    dueDate: null,
    reminder: {},
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email.";
      },
      currency: (value) => {
        const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
        return pattern.test(value) || "Invalid currency amount.";
      },
    },
  }),
};
</script>