<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn id="add-tenant-btn" v-on="on" text>Add Tenant</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-container grid-list-sm>
        <v-form ref="tenantForm" v-model="valid">
          <v-layout row wrap>
            <v-flex xs12 align-center justify-space-between>
              <v-text-field
                v-model="tenant.name"
                prepend-icon="mdi-form-textbox"
                label="Tenant's Name"
                :rules="[rules.required]"
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="tenant.email"
                prepend-icon="mdi-mail"
                label="Email Address"
                :rules="[rules.required, rules.email]"
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="tenant.phone"
                type="tel"
                prepend-icon="mdi-phone"
                label="Telephone"
                :rules="[rules.required]"
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startDate"
                    label="Tenancy Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    :rules="[rules.required]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endDate"
                    label="Tenancy End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="tenant.rent"
                prefix="£"
                prepend-icon="mdi-cash"
                label="Monthly Rent"
                :rules="[rules.required, rules.currency]"
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                v-model="tenant.notes"
                prepend-icon="mdi-note"
                label="Notes"
              ></v-textarea>
            </v-flex>
            <v-flex xs12>
              <v-checkbox
                v-model="tenant.current"
                label="Current Tenant"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="save()" :disabled="!valid"
          >Save</v-btn
        >
        <v-btn text @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import moment from "moment";
export default {
  name: "TenantDialog",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  methods: {
    save() {
      if (this.valid) {
        // Set the tenant's address from the property for now
        if (!this.tenant.address) {
          this.tenant.address = {};
          this.tenant.address.address = this.property.address.address;
          this.tenant.address.address2 = this.property.address.address2;
          this.tenant.address.city = this.property.address.city;
          this.tenant.address.country = this.property.address.country;
          this.tenant.address.postalCode = this.property.address.postalCode;
          this.tenant.address.province = this.property.address.province;
        }
        // Convert dates from String to Epoc
        this.tenant.startDate = moment
          .utc(this.startDate, "YYYY-MM-DD")
          .startOf("day")
          .valueOf();
        if (this.endDate) {
          this.tenant.endDate = moment
            .utc(this.endDate, "YYYY-MM-DD")
            .startOf("day")
            .valueOf();
        }
        if (this.tenant.id) {
          API.put(
            "sites",
            "/sites/" + this.property.id + "/tenants/" + this.tenant.id,
            {
              body: this.tenant,
            }
          ).then(() => {
            this.showDialog = false;
            this.$refs.tenantForm.resetValidation();
            this.tenant = { current: true };
            this.$emit("tenant-updated");
          });
        } else {
          API.post("sites", "/sites/" + this.property.id + "/tenants/", {
            body: this.tenant,
          }).then(() => {
            this.showDialog = false;
            this.$refs.tenantForm.resetValidation();
            this.tenant = { current: true };
            this.$emit("tenant-added");
          });
        }
      }
    },
    cancel() {
      this.showDialog = false;
      this.$refs.tenantForm.resetValidation();
      this.tenant = { current: true };
    },
    edit(tenant) {
      this.tenant = {
        id: tenant.id,
        name: tenant.name,
        current: tenant.current,
        email: tenant.email,
        phone: tenant.phone,
        rent: tenant.rent,
        startDate: tenant.startDate,
        endDate: tenant.endDate,
        notes: tenant.notes
      };
      this.startDate = moment(this.tenant.startDate).format("YYYY-MM-DD");
      if (this.tenant.endDate) {
        this.endDate = moment(this.tenant.endDate).format("YYYY-MM-DD");
      }
      this.showDialog = true;
    },
  },
  computed: {
    title() {
      return this.tenant.id ? "Edit Tenant" : "Create Tenant";
    },
  },
  data: () => ({
    showDialog: false,
    valid: false,
    startDateMenu: false,
    endDateMenu: false,
    startDate: null,
    endDate: null,
    tenant: { current: true },
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email.";
      },
      currency: (value) => {
        const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
        return pattern.test(value) || "Invalid currency amount.";
      },
    },
  }),
};
</script>