import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
  },
  getters: {
    engineerManagerId: state => {
      if (state.user) {
        return state.user.attributes['custom:engineerManagerId2']
      }
    }
  }
})