import Vue from 'vue'
import Router from 'vue-router'
import PropertyList from '@/components/PropertyList'
import PropertyDetails from '@/components/PropertyDetails'
import TenantList from '@/components/TenantList'
import { components } from 'aws-amplify-vue';
import { AmplifyEventBus } from 'aws-amplify-vue';
import * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import store from '@/store';

Vue.use(Router)
Vue.use(AmplifyPlugin, AmplifyModules);

let user;

getUser().then((user) => {
  if (user) {
    //router.push({ path: '/' })
  }
})

AmplifyEventBus.$on('authState', async (state) => {
  if (state === 'signedOut') {
    user = null;
    store.commit('setUser', null);
    router.push({ path: '/auth' })
  } else if (state === 'signedIn') {
    user = await getUser();
    router.push({ path: '/' })
  }
});

function getUser() {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data);
      return data;
    }
  }).catch(() => {
    store.commit('setUser', null);
    return null
  });
}

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/properties"
    }, {
      path: '/properties',
      name: 'PropertyList',
      component: PropertyList,
      meta: { requiresAuth: true }
    }, {
      path: '/property/:id',
      name: 'PropertyDetails',
      component: PropertyDetails,
      meta: { requiresAuth: true }
    }, {
      path: '/tenants',
      name: 'TenantList',
      component: TenantList,
      meta: { requiresAuth: true }
    }, {
      path: '/auth',
      name: 'Authenticator',
      component: components.Authenticator
    }
  ]
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser();
    if (!user) {
      return next({
        path: '/auth',
        query: {
          redirect: to.fullPath,
        }
      });
    }
    return next()
  }
  return next()
})


export default router;