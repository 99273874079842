<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn id="add-reminder-btn" v-on="on" text>Add Reminder</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">Add Reminder</v-card-title>
      <v-container grid-list-sm>
        <v-form ref="reminderForm" v-model="valid">
          <v-layout row wrap>
            <v-flex xs12>
              <v-select
                v-model="reminder.type"
                :items="reminderTypes"
                menu-props="auto"
                label="Select"
                hide-details
                prepend-icon="mdi-reminder"
                single-line
                :rules="[rules.required]"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-menu
                v-model="dueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dueDate"
                    label="Reminder Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    :rules="[rules.required]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dueDate"
                  @input="dueDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="save()" :disabled="!valid"
          >Save</v-btn
        >
        <v-btn text @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import moment from "moment";
export default {
  name: "ReminderDialog",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  methods: {
    save() {
      if (this.valid) {
        // Convert dates from String to Epoc
        this.reminder.dueDate = moment
          .utc(this.dueDate, "YYYY-MM-DD")
          .startOf("day")
          .valueOf();
        API.post("sites", "/sites/" + this.property.id + "/reminders/", {
          body: this.reminder,
        }).then(() => {
          this.showDialog = false;
          this.$refs.reminderForm.reset();
          this.$emit("reminder-added");
        });
      }
    },
    cancel() {
      this.showDialog = false;
      this.$refs.reminderForm.reset();
      this.reminder = {};
    },
  },
  data: () => ({
    showDialog: false,
    valid: false,
    dueDateMenu: false,
    dueDate: null,
    reminder: {},
    reminderTypes: [
      // { text: "Gas Safety Check" },
      // { text: "Electrical Safety Check" },
      { text: "Insurance Renewal" },
      { text: "Mortgage Rate Change" },
      // { text: "EPC" },
      // { text: "Smoke and CO Alarm Check" },
    ],
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email.";
      },
      currency: (value) => {
        const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
        return pattern.test(value) || "Invalid currency amount.";
      },
    },
  }),
};
</script>