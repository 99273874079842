<template>
  <v-card>
    <v-toolbar color="blue darken-3" dark>
      <v-toolbar-title>Log</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <div class="grid-container text-body-2" v-for="log in logs" :key="log.id">
        <div class="grid-item text--secondary"><v-icon>mdi-note</v-icon></div>
        <div class="grid-item text--secondary">Note added by {{log.createdBy.name}}</div>
        <div class="grid-item date-item text--secondary">{{ log.createdOn | moment("Do MMM YYYY") }}</div>  
        <div class="grid-item log-text-item">{{log.content}}</div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
export default {
  name: "PropertyLogPanel",
  components: {  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    logs: [],
  }),
  methods: {
    async loadLogs() {
      try {
        this.logs = await API.get(
          "sites",
          "/sites/" + this.property.id + "/notes",
          {}
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // you can handle different errors differently
        // or just display an error message instead of your table using a <v-if> tag
        // or navigate to an error page
      }
    },
  },
  mounted() {
    this.loadLogs();
  },
};
</script>

<style scoped>
  .grid-container {
    margin: 4px;
    display: grid;
    grid-template-columns: 40px auto 30%;
  }
  .grid-item {
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    text-align: left;
  }
  .log-text-item {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .date-item {
    text-align: right;
  }
</style>