<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <v-card>
      <v-card-title class="headline">Add {{ detail.name }}</v-card-title>
      <div v-html="helpText" class="help-text"></div>
      <v-container grid-list-sm>
        <v-form ref="addManualForm" v-model="valid">
          <v-layout row wrap>
            <v-flex xs12>
              <v-menu
                v-model="checkDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="checkDate"
                    label="Inspection Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    :rules="[rules.required]"
                    v-on="on"
                    :hint="checkDateHint"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="checkDate"
                  @input="checkDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="save()"
          :disabled="saveButtonDisabled"
          :loading="saving"
          >Save</v-btn
        >
        <v-btn text @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import moment from "moment";
export default {
  name: "AddManualComplianceDialog",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addManual(detail, defaultStartDate) {
      this.detail = detail;
      this.showDialog = true;
      if (defaultStartDate) {
        this.checkDate = moment(defaultStartDate).format("YYYY-MM-DD");
      } else {
        this.checkDate = moment().format("YYYY-MM-DD");
      }
    },
    save() {
      if (this.valid) {
        this.compliance.type = this.detail.type;
        // Convert dates from String to Epoc
        this.compliance.checkDate = moment
          .utc(this.checkDate, "YYYY-MM-DD")
          .startOf("day")
          .valueOf();
        if (this.compliances[this.detail.type].duration) {
          this.compliance.expiryDate = moment
            .utc(this.checkDate, "YYYY-MM-DD")
            .startOf("day")
            .add(this.compliances[this.detail.type].duration, "months")
            .subtract(1, "days")
            .valueOf();
        }
        this.saving = true;
        API.post("sites", "/sites/" + this.property.id + "/compliance/", {
          body: this.compliance,
        }).then(() => {
          this.compliance = {};
          this.showDialog = false;
          this.$refs.addManualForm.reset();
          this.$emit("compliance-added");
          this.saving = false;
        });
      }
    },
    cancel() {
      this.showDialog = false;
      this.$refs.addManualForm.reset();
      this.compliance = {};
    },
  },
  data: () => ({
    compliances: {
      GAS: {
        helpText: `
<div>To ensure your tenants’ safety, all gas appliances and flues need to undergo an annual gas safety check - and always by a Gas Safe registered engineer </div>
<div>We recommend that you request a gas safety check via Miniao, however if you have organised a check yourself please fill out the details below. </div>
<div><strong>Note, you are responsible for ensuring the gas safety check details are correct. </strong></div>
`,
        duration: 12,
      },
      EICR: {
        helpText: `
<div>An Electrical Installation Condition Report (EICR) is a report carried out to assess the safety of the existing electrical installation within a property and is used to describe its condition. </div>
<div>We recommend that you request an EICR via Miniao, however if you have organised a check yourself please fill out the details below. </div>
<div><strong>Note, you are responsible for ensuring the EICR details are correct. </strong></div>
`,
        duration: 60,
      },
      SMOKE: {
        helpText: `
<div>Landlords are required to have at least one smoke alarm installed on every storey of their properties and a carbon monoxide alarm in any room containing a gas appliance. The landlord must make sure the alarms are in working order at the start of each new tenancy. </div>
<div>We recommend that you request a check via Miniao, however if you have organised a check yourself please fill out the details below. </div>
<div><strong>Note, you are responsible for ensuring the required smoke alarms and carbon monoxide alarms are present and working. </strong></div>
`,
      },
      EPC: {
        helpText: `
<div>By law all properties must have an EPC (Energy Performance Certificate) in place with a rating of E or above in order to advertise the property for rent. </div>
<div>We recommend that you request a check via Miniao, however if you have organised a check yourself please fill out the details below. </div>
<div><strong>Note, you are responsible for ensuring the EPC is valid. </strong></div>
`,
        duration: 120,
      },
    },
    showDialog: false,
    valid: false,
    saving: false,
    checkDateMenu: false,
    checkDate: null,
    detail: {},
    compliance: {},
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email.";
      },
      currency: (value) => {
        const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
        return pattern.test(value) || "Invalid currency amount.";
      },
    },
  }),
  computed: {
    saveButtonDisabled() {
      return !this.valid || this.saving;
    },
    checkDateHint() {
      return "Date of the " + this.detail.name.toLowerCase() + " inspection";
    },
    helpText() {
      return this.detail.type ? this.compliances[this.detail.type].helpText : "";
    },
  },
};
</script>

<style>
.help-text {
  color: rgba(0, 0, 0, 0.6);
}
.help-text div {
  padding: 0 24px 20px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}
</style>