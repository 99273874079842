<template>
  <div>
    <v-btn bottom color="pink" dark fab fixed right @click="showDialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="showDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>Add Property</v-card-title>
        <v-container grid-list-sm>
          <v-form ref="propertyForm" v-model="valid">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="property.address.address"
                  prepend-icon="mdi-home"
                  label="Address line 1"
                  :rules="[rules.required]"
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="property.address.address2"
                  prepend-icon="mdi-home-outline"
                  label="Address line 2"
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="property.address.city"
                  prepend-icon="mdi-city"
                  label="Town"
                  :rules="[rules.required]"
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="property.address.province"
                  prepend-icon="mdi-map"
                  label="County"
                  :rules="[rules.required]"
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="property.address.postalCode"
                  prepend-icon="mdi-postage-stamp"
                  label="Postcode"
                  :rules="[rules.required]"
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="property.gasAtProperty"
                  label="Gas at Property"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save()" :disabled="!valid">Save</v-btn>
          <v-btn text @click="cancel()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import store from "@/store";
export default {
  name: "PropertyDialog",
  data: () => ({
    showDialog: false,
    valid: false,
    property: { address: {}, gasAtProperty: true },
    rules: {
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email.";
      },
      currency: value => {
        const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
        return pattern.test(value) || "Invalid currency amount.";
      }
    }
  }),
  methods: {
    save() {
      if (this.valid) {
        // eslint-disable-next-line no-console
        console.log(this.property)
        this.property.address.country = "GB";
        API.post(
          "customers",
          "/customers/" + store.getters.engineerManagerId + "/sites",
          {
            body: this.property
          }
        ).then(() => {
          this.showDialog = false;
          this.$refs.propertyForm.reset();
          this.$emit("property-added");
        });
      }
    },
    cancel() {
      this.showDialog = false;
      this.$refs.propertyForm.reset();
      this.property = { address: {} };
    }
  }
};
</script>