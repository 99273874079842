<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="signedIn">
        <v-list-item-title>
          <strong>{{username}}</strong>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="signedIn" @click="logout">
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="!signedIn" to="/auth">
        <v-list-item-title>login</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";

import store from '@/store';

export default {
  name: "UserMenu",
  computed: {
    username() {
      return store.state.user.username;
    },
    signedIn() {
      return store.state.user != null;
    }
  },
  methods: {
    logout() {
      this.$Amplify.Auth.signOut().then(() => {
        AmplifyEventBus.$emit("authState", "signedOut");
      });
    }
  }
};
</script>