<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-for="item in items">
          <v-list-item :key="item.text" :to="item.href">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">Miniaio</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <notification-menu></notification-menu>
      <user-menu></user-menu>
    </v-app-bar>
    <v-main>
      <router-view @success="success"></router-view>
    </v-main>
    <v-snackbar v-model="showSnackbar" color="success">{{successMessage}}</v-snackbar>
  </v-app>
</template>

<script>
import UserMenu from "./components/UserMenu";
import NotificationMenu from "./components/NotificationMenu";

export default {
  name: "App",

  components: {
    UserMenu,
    NotificationMenu
  },

  data: () => ({
    dialog: false,
    drawer: null,
    items: [
      { icon: "mdi-home", text: "Properties", href: "/properties" },
      { icon: "mdi-account", text: "Tenants", href: "/tenants" },
      // { icon: "mdi-settings", text: "Settings", href: "/" },
      // { icon: "mdi-help", text: "Help", href: "/" }
    ],
    successMessage: null,
    showSnackbar: false
  }),

  methods: {
    success(message) {
      this.successMessage = message;
      this.showSnackbar = true;
    }
  }
};
</script>

<style scoped>
  .v-app-bar {
    padding-top: env(safe-area-inset-top)
  }
</style>

<!-- Styles for Cognito login form -->
<style>
[data-test=sign-in-section], [data-test=sign-up-section] {
    margin: auto;
    width: 25%;
    display: block;
}
</style>
