<template>
  <v-menu offset-y v-if="display">
    <template v-slot:activator="{ on }">
      <v-btn id="notification-button" icon v-on="on">
        <v-badge
          color="red"
          overlap
          :content="notificationCount"
          :value="notificationCount"
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list two-line v-if="signedIn" id="user-menu">
      <v-subheader>Notifications</v-subheader>
      <v-list-item-group>
        <v-list-item
          v-for="notification in notifications"
          v-bind:key="notification.id"
          :to="notification.link"
          :disabled="notification.link == ''"
        >
          <v-list-item-avatar v-if="notification.level == 'WARN'">
            <v-icon class="orange white--text">mdi-alert</v-icon>
          </v-list-item-avatar>
          <v-list-item-avatar v-if="notification.level != 'WARN'">
            <v-icon class="grey white--text">mdi-information</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ notification.subject }}</v-list-item-title>
            <v-list-item-subtitle>
              <span v-html="notification.message"></span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import store from "@/store";

export default {
  name: "NotificationMenu",
  data: () => ({
    notifications: [],
    notificationCount: 0,
    loaded: false,
    display: true,
  }),
  computed: {
    signedIn() {
      return store.state.user != null;
    },
  },
  methods: {
    async getNotifications() {
      if (store.getters.engineerManagerId) {
        this.display = true;
        try {
          let response = await API.get(
            "customers",
            "/customers/" + store.getters.engineerManagerId + "/notifications",
            {}
          );
          this.notifications = response;
          this.notificationCount = response.length;
          this.loaded = true;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          // you can handle different errors differently
          // or just display an error message instead of your table using a <v-if> tag
          // or navigate to an error page
        }
      } else {
        this.display = false;
      }
    },
  },
  mounted() {
    store.subscribe((mutation) => {
      if (mutation.type == "setUser") {
        this.getNotifications();
      }
    });
  },
};
</script>

<style scoped>
#user-menu {
  max-width: 750px;
}
</style>