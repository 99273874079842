<template>
  <v-container v-if="loaded == true">
    <v-row align-content="start" align="start" v-if="properties.length > 0">
      <v-col v-for="property in properties" v-bind:key="property.id">
        <PropertyThumbnail :property="property"></PropertyThumbnail>
      </v-col>
    </v-row>
    <p v-if="properties.length == 0">You don't have any properties registered</p>
    <PropertyDialog @property-added="propertyAdded"></PropertyDialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import PropertyThumbnail from "./PropertyThumbnail";
import PropertyDialog from "./PropertyDialog";
import store from "@/store";

export default {
  name: "PropertyList",

  components: {
    PropertyThumbnail,
    PropertyDialog
  },

  data: () => ({
    properties: [],
    loaded: false
  }),

  methods: {
    async getSites() {
      try {
        let response = await API.get(
          "customers",
          "/customers/" + store.getters.engineerManagerId + "/sites",
          {}
        );
        this.properties = response;
        this.loaded = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // you can handle different errors differently
        // or just display an error message instead of your table using a <v-if> tag
        // or navigate to an error page
      }
    },
    propertyAdded() {
      this.$emit("success", "Property added");
      this.getSites();
    }
  },

  mounted() {
    this.getSites();
  }
};
</script>
