<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ attachment.name }}</v-toolbar-title>
      </v-toolbar>
      <v-img :src="attachment.url" aspect-ratio="1.5" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
export default {
  name: "ViewAttachmentDialog",
  methods: {
    show(attachment) {
      this.attachment = attachment;
      this.showDialog = true;
    },
  },
  data: () => ({
    showDialog: false,
    attachment: {},
    url: "",
  }),
};
</script>