<template>
  <v-card>
    <v-toolbar color="blue darken-3" dark>
      <v-toolbar-title>Reminders</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" style="width: 48px"></th>
              <th class="text-left">Reminder</th>
              <th class="text-left">Due Date</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reminder in reminders" :key="reminder.id">
              <td>
                <v-icon v-if="reminder.resolved" small color="green"
                  >mdi-check-circle</v-icon
                >
              </td>
              <td>{{ reminder.type }}</td>
              <td>{{ reminder.dueDate | moment("dddd, Do MMMM YYYY") }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="renew(reminder)"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-autorenew</v-icon
                    >
                  </template>
                  <span>Renew {{ reminder.type }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr v-if="reminders.length == 0">
              <td colspan="4">No reminders for this property...</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <ReminderDialog
        :property="property"
        @reminder-added="reminderAdded"
      ></ReminderDialog>
    </v-card-actions>
    <RenewalDialog
      ref="renewalDialog"
      @reminder-renewed="reminderRenewed"
    ></RenewalDialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import ReminderDialog from "./ReminderDialog";
import RenewalDialog from "./RenewalDialog";
export default {
  name: "PropertyRemindersPanel",
  components: {
    ReminderDialog,
    RenewalDialog,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    reminders: [],
  }),
  methods: {
    async loadReminders() {
      function compare(a, b) {
        if (a.dueDate > b.dueDate) return -1;
        if (a.dueDate < b.dueDate) return 1;
        return 0;
      }
      try {
        let response = await API.get(
          "sites",
          "/sites/" + this.property.id + "/reminders",
          {}
        );
        response.sort(compare);
        // TODO: Needs a more elegant solution if we are not going to show reminders that are related to compliance
        this.reminders = response.filter(
          (reminder) =>
            reminder.type != "Gas Safety Check" && reminder.type != "EICR"
        );
        this.loaded = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.response);
      }
    },
    reminderAdded() {
      this.$emit("success", "Reminder added");
      this.loadReminders();
    },
    reminderRenewed() {
      this.$emit("success", "Reminder Renewed");
      this.loadReminders();
    },
    renew(reminder) {
      this.$refs.renewalDialog.renewReminder(reminder);
    },
  },
  mounted() {
    this.loadReminders();
  },
};
</script>