<template>
  <v-card>
    <v-toolbar color="blue darken-3" dark>
      <v-toolbar-title>Tenants</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" style="width: 48px"></th>
              <th class="text-left">Name</th>
              <th class="text-left d-none d-lg-table-cell">Start Date</th>
              <th class="text-left d-none d-lg-table-cell">End Date</th>
              <th class="text-left d-none d-sm-table-cell">Telephone</th>
              <th class="text-left d-none d-sm-table-cell">Email</th>
              <th class="text-left">Rent</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tenant in tenants" :key="tenant.id">
              <td>
                <v-tooltip bottom v-if="tenant.current">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small color="green" v-bind="attrs" v-on="on">
                      mdi-home-circle
                    </v-icon>
                  </template>
                  <span>Current Tenant</span>
                </v-tooltip>
              </td>
              <td>{{ tenant.name }}</td>
              <td class="d-none d-lg-table-cell">{{ tenant.startDate | moment("Do MMMM YYYY") }}</td>
              <td class="d-none d-lg-table-cell">{{ tenant.endDate | moment("Do MMMM YYYY") }}</td>
              <td class="d-none d-sm-table-cell">
                <a :href="phoneUrl(tenant.phone)">{{ tenant.phone }}</a>
              </td>
              <td class="d-none d-sm-table-cell">
                <a :href="emailUrl(tenant.email)">{{ tenant.email }}</a>
              </td>
              <td>{{ formatRent(tenant.rent) }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="edit(tenant)"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Edit {{ tenant.name }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr v-if="tenants.length == 0">
              <td colspan="8">No tenants for this property...</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <TenantDialog
        :property="property"
        @tenant-added="tenantAdded"
        @tenant-updated="tenantUpdated"
        ref="tenantDialog"
      ></TenantDialog>
    </v-card-actions>
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import TenantDialog from "./TenantDialog";
export default {
  name: "PropertyTenantsPanel",
  components: {
    TenantDialog,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async loadTenants() {
      function compare(a, b) {
        if (a.startDate > b.startDate) return -1;
        if (a.startDate < b.startDate) return 1;
        return 0;
      }
      try {
        let response = await API.get(
          "sites",
          "/sites/" + this.property.id + "/tenants",
          {}
        );
        response.sort(compare);
        this.tenants = response;
        this.loaded = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // you can handle different errors differently
        // or just display an error message instead of your table using a <v-if> tag
        // or navigate to an error page
      }
    },
    edit(tenant) {
      this.$refs.tenantDialog.edit(tenant);
    },
    phoneUrl(phone) {
      return "tel:" + phone;
    },
    emailUrl(email) {
      return "mailto:" + email;
    },
    formatRent(value) {
      let val = (value / 1).toFixed(2);
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    tenantAdded() {
      this.$emit("success", "Tenant added");
      this.loadTenants();
    },
    tenantUpdated() {
      this.$emit("success", "Tenant updated");
      this.loadTenants();
    },
  },
  data: () => ({
    loaded: false,
    tenants: [],
  }),
  mounted() {
    this.loadTenants();
  },
};
</script>