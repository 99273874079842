<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon :color="report.colour" v-bind="attrs" v-on="on">{{
          report.icon
        }}</v-icon>
      </template>
      <span>{{ report.message }}</span>
    </v-tooltip>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
export default {
  name: "PropertyComplianceSummary",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    report: {},
  }),
  methods: {
    async loadCompliance() {
      try {
        var report = await API.get(
          "sites",
          "/sites/" + this.property.id + "/complianceReport",
          {}
        );

        if (report.compliant == "YES") {
          report.colour = "green";
        } else if (report.compliant == "WARNING") {
          report.colour = "orange";
        } else {
          report.colour = "red";
        }
        report.icon =
          report.compliant == "YES"
            ? "mdi-check-circle-outline"
            : "mdi-alert-circle-outline";
        if (report.compliant != "YES") {
          for (var i = 0; i < report.details.length; i++) {
            if (report.details[i].compliant != "YES") {
              report.message = report.details[i].message;
              break;
            }
          }
        } else {
          report.message = "Property is compliant";
        }

        this.report = report;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // you can handle different errors differently
        // or just display an error message instead of your table using a <v-if> tag
        // or navigate to an error page
      }
    },
  },
  mounted() {
    this.loadCompliance();
  },
};
</script>