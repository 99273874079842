import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
// eslint-disable-next-line no-unused-vars
import Amplify, { API }  from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

Vue.use(require('vue-moment'));

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
