<template>
  <v-alert v-if="!compliant" type="warning" icon="mdi-alert">
    {{ message }}
  </v-alert>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
export default {
  name: "PropertyComplianceAlert",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    compliant: true,
    message: "",
  }),
  methods: {
    async loadCompliance() {
      try {
        let report = await API.get(
          "sites",
          "/sites/" + this.property.id + "/complianceReport",
          {}
        );
        this.compliant = report.compliant;
        if (!report.compliant) {
          for (var i = 0; i < report.details.length; i++) {
            if (!report.details[i].compliant) {
              this.message = report.details[i].message;
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // you can handle different errors differently
        // or just display an error message instead of your table using a <v-if> tag
        // or navigate to an error page
      }
    },
  },
  mounted() {
    this.loadCompliance();
  },
};
</script>