<template>
  <v-card class="mx-auto" width="300" max-width="300" height="328px">
    <router-link :to="'/property/' + property.id" style="text-decoration: none">
      <v-img
        class="white--text align-end grey lighten-2"
        height="200px"
        :src="propertyImageUrl"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-card-title>{{ property.address.address }}</v-card-title>
      </v-img>
    </router-link>

    <v-card-text class="text--primary property-address">
      <div>{{ property.address.line }}</div>
    </v-card-text>

    <div class="image-container">
      <v-card-actions>
        <v-btn :to="'/property/' + property.id" text>Details</v-btn>
      </v-card-actions>
      <div class="bottom-right"><property-compliance-summary :property="property"></property-compliance-summary></div>
    </div>
  </v-card>
</template>

<script>
import Storage from "@aws-amplify/storage";
import PropertyComplianceSummary from './PropertyComplianceSummary.vue';

export default {
  name: "PropertyThumbnail",

  components: { PropertyComplianceSummary },

  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  created() {
    var image = this.property.primaryImage
      ? this.property.primaryImage
      : "defaultPrimaryImage.jpg";
    Storage.get(image, {})
      .then((url) => {
        this.propertyImageUrl = url;
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e));
  },

  data: () => ({
    propertyImageUrl: "",
  }),
};
</script>

<style scoped>
.image-container {
  position: relative;
}
.bottom-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.property-address {
  height: 76px;
}
</style>