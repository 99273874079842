<template>
  <v-card>
    <v-toolbar color="blue darken-3" dark>
      <v-toolbar-title>Attachments</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab>Photos</v-tab>
        <v-tab>Files</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row>
            <v-col
              v-for="attachment in attachments"
              :key="attachment.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-img
                :src="attachment.url"
                @click="showAttachment(attachment)"
                aspect-ratio="1"
                class="grey lighten-2"
                style="cursor: pointer"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Reminder</th>
                  <th class="text-left">Uploaded Date</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="attachment in attachments"
                  :key="attachment.id"
                  @click="showAttachment(attachment)"
                  style="cursor: pointer"
                >
                  <td>{{ attachment.name }}</td>
                  <td>
                    {{ attachment.createdOn | moment("dddd, Do MMMM YYYY") }}
                  </td>
                  <td></td>
                </tr>
                <tr v-if="attachments.length == 0">
                  <td colspan="4">No files for this property...</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <AttachmentDialog
        :property="property"
        @attachment-added="attachmentAdded"
      ></AttachmentDialog>
    </v-card-actions>
    <ViewAttachmentDialog ref="viewAttachmentDialog"></ViewAttachmentDialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import Storage from "@aws-amplify/storage";
import AttachmentDialog from "./AttachmentDialog";
import ViewAttachmentDialog from "./ViewAttachmentDialog";
export default {
  name: "PropertyAttachmentsPanel",
  components: {
    AttachmentDialog,
    ViewAttachmentDialog,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    attachments: [],
    tab: null,
  }),
  methods: {
    async loadAttachments() {
      function compare(a, b) {
        if (a.lastUpdated > b.lastUpdated) return -1;
        if (a.lastUpdated < b.lastUpdated) return 1;
        return 0;
      }
      try {
        let response = await API.get(
          "sites",
          "/sites/" + this.property.id + "/attachments",
          {}
        );
        response.sort(compare);
        for (var i = 0; i < response.length; i++) {
          var ext = response[i].url.substring(
            response[i].url.lastIndexOf(".") + 1
          );
          if (ext != "pdf") {
            let url = await Storage.get(response[i].url, {});
            response[i].url = url;
          } else {
            let url = await Storage.get("pdf.png", {});
            response[i].url = url;
          }
        }
        this.attachments = response;
        this.loaded = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.response);
      }
    },
    attachmentAdded() {
      this.$emit("success", "Attachment added");
      this.loadAttachments();
    },
    showAttachment(attachment) {
      this.$refs.viewAttachmentDialog.show(attachment);
    },
  },
  mounted() {
    this.loadAttachments();
  },
};
</script>