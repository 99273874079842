<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn id="add-attachment-btn" v-on="on" text>Add Attachment</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">Add Attachment</v-card-title>
      <v-container grid-list-sm>
        <v-layout row wrap>
          <v-flex xs12>
            <v-form ref="attachmentForm">
              <v-file-input
                accept="image/*,.pdf"
                label="File input"
                :disabled="disabled"
                @change="onChange"
                @click:clear="onClear"
              ></v-file-input>
            </v-form>
          </v-flex>
          <v-flex xs12>
            <v-progress-linear v-model="uploadProgress"></v-progress-linear>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="save()" :disabled="!valid">
          Save
        </v-btn>
        <v-btn text @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import Storage from "@aws-amplify/storage";

export default {
  name: "AttachmentDialog",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async onChange(file) {
      if (file && file.name) {
        this.disabled = true;
        var theThis = this;
        const { key } = await Storage.put((this.uuid() + "-" + file.name).replace(/\s+/g, '-'), file, {
          progressCallback(progress) {
            theThis.uploadProgress = (progress.loaded / progress.total) * 100;
          },
        });
        this.valid = true;
        this.disabled = false;
        this.attachment = {
          name: file.name,
          url: key,
          filename: file.name,
        };
      }
    },
    onClear() {
      this.valid = false;
      this.uploadProgress = 0;
    },
    uuid() {
      var dt = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    },
    save() {
      if (this.valid) {
        API.post("sites", "/sites/" + this.property.id + "/attachments/", {
          body: this.attachment,
        }).then(() => {
          this.showDialog = false;
          this.$refs.attachmentForm.reset();
          this.uploadProgress = 0;
          this.attachment = {};
          this.valid = false;
          this.$emit("attachment-added");
        });
      }
    },
    cancel() {
      this.showDialog = false;
      this.$refs.attachmentForm.reset();
      this.uploadProgress = 0;
      this.attachment = {};
    },
  },
  data: () => ({
    showDialog: false,
    valid: false,
    disabled: false,
    uploadProgress: 0,
    attachment: {},
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email.";
      },
      currency: (value) => {
        const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
        return pattern.test(value) || "Invalid currency amount.";
      },
    },
  }),
};
</script>