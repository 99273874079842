<template>
  <v-card>
    <v-toolbar color="blue darken-3" dark>
      <v-toolbar-title>Jobs</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Status</th>
              <th class="text-left">Requested Date</th>
              <th class="text-left">Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="job in jobs" :key="job.id">
              <td>{{ job.statusString }}</td>
              <td>{{ job.requestedDateFrom | moment("DD/MM/YYYY HH:mm") }} - {{ job.requestedDateTo | moment("HH:mm") }}</td>
              <td>{{ job.notes }}</td>
            </tr>
            <tr v-if="jobs.length == 0">
              <td colspan="3">No jobs for this property...</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <RequestJobDialog :property="property" @job-requested="jobRequested"></RequestJobDialog>
    </v-card-actions>
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import RequestJobDialog from "./RequestJobDialog";
export default {
  name: "PropertyJobsPanel",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  components:{
    RequestJobDialog
  },
  methods: {
    async loadJobs() {
      function compare(a, b) {
        if (a.requestedDateFrom > b.requestedDateFrom) return -1;
        if (a.requestedDateFrom < b.requestedDateFrom) return 1;
        return 0;
      }
      try {
        let response = await API.get(
          "sites",
          "/sites/" + this.property.id + "/jobs",
          {}
        );
        response.sort(compare);
        this.jobs = response;
        this.loaded = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // you can handle different errors differently
        // or just display an error message instead of your table using a <v-if> tag
        // or navigate to an error page
      }
    },
    jobRequested() {
      this.$emit("success", "Job requested");
      this.loadJobs();
    },
  },
  data: () => ({
    loaded: false,
    jobs: [],
  }),
  mounted() {
    this.loadJobs();
  },
};
</script>