<template>
  <v-card>
    <v-toolbar color="blue darken-3" dark>
      <v-toolbar-title>Compliance</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="detail in report.details" :key="detail.message">
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      :color="colour(detail)"
                      v-bind="attrs"
                      v-on="on"
                      >{{ icon(detail) }}</v-icon
                    >
                  </template>
                  <span v-html="readDescription(detail)"></span>
                </v-tooltip>
              </td>
              <td>{{ detail.name }}</td>
              <td
                class="d-none d-md-table-cell"
                v-html="readDescription(detail)"
              ></td>
              <td>
                <v-row align="center" justify="space-around">
                  <v-btn
                    :disabled="!allowRequest(detail)"
                    @click="request(detail)"
                    small
                    color="primary"
                    depressed
                    >Request</v-btn
                  >
                </v-row>
              </td>
              <td>
                <v-row>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item link :disabled="!allowAddManual(detail)">
                        <v-list-item-title @click="addManual(detail)"
                          >Add Manually</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <ManualComplianceDialog
      :property="property"
      @compliance-added="complianceAdded"
      @compliance-updated="complianceUpdated"
      ref="manualComplianceDialog"
    />
    <RequestComplianceDialog
      :property="property"
      @job-requested="complianceRequested"
      ref="requestComplianceDialog"
    />
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import RequestComplianceDialog from "./RequestComplianceDialog";
import ManualComplianceDialog from "./ManualComplianceDialog";
import { format } from "date-fns";
export default {
  name: "PropertyCompliancePanel",
  components: { ManualComplianceDialog, RequestComplianceDialog },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    report: {},
  }),
  methods: {
    async loadCompliance() {
      try {
        this.report = await API.get(
          "sites",
          "/sites/" + this.property.id + "/complianceReport",
          {}
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // you can handle different errors differently
        // or just display an error message instead of your table using a <v-if> tag
        // or navigate to an error page
      }
    },
    request(detail) {
      this.$refs.requestComplianceDialog.request(detail, detail.expiryDate);
    },
    addManual(detail) {
      this.$refs.manualComplianceDialog.addManual(detail);
    },
    icon(detail) {
      if (detail.compliant == "YES") {
        return "mdi-check";
      } else if (detail.compliant == "PENDING") {
        return "mdi-dots-horizontal-circle-outline";
      } else {
        return "mdi-alert-circle-outline";
      }
    },
    colour(detail) {
      if (detail.compliant == "YES") {
        return "green";
      } else if (detail.compliant == "PENDING") {
        return "blue";
      } else if (detail.compliant == "WARNING") {
        return "orange";
      } else {
        return "red";
      }
    },
    readDescription(detail) {
      if (detail.type == "GAS" && !this.property.gasAtProperty) {
        return "No gas at property";
      }
      if(detail.compliant == "PENDING"){
        return detail.message;
      }
      if (!detail.checkDate) {
        return detail.name + " not present";
      } else {
        if (detail.type == "SMOKE") {
          return detail.name + " valid until the end of the current tenancy";
        } else {
          if (detail.compliant == "YES") {
            return (
              detail.name +
              " valid until <strong>" +
              format(detail.expiryDate, "dddd, Do MMMM YYYY") +
              "</strong>"
            );
          } else if (detail.compliant == "WARNING") {
            return (
              detail.name +
              " expires on <strong>" +
              format(detail.expiryDate, "dddd, Do MMMM YYYY") +
              "</strong>"
            );
          } else {
            return (
              "Expired on " + format(detail.expiryDate, "dddd, Do MMMM YYYY")
            );
          }
        }
      }
    },
    allowRequest(detail) {
      return (
        detail.compliant != "YES" &&
        detail.compliant != "PENDING" &&
        this.property.allowJobRequests &&
        (detail.type == "GAS" || detail.type == "EICR")
      );
    },
    allowAddManual(detail) {
      return detail.compliant != "YES";
    },
    complianceRequested() {
      this.$emit("success", "Compliance requested");
      this.loadCompliance();
    },
    complianceAdded() {
      this.$emit("success", "Compliance added");
      this.loadCompliance();
    },
    complianceUpdated() {
      this.$emit("success", "Compliance updated");
      this.loadCompliance();
    },
  },
  mounted() {
    this.loadCompliance();
  },
};
</script>