<template>
  <v-dialog v-model="showDialog" persistent max-width="800px">
    <v-card>
      <v-card-title class="headline">{{ title() }}</v-card-title>
      <v-container grid-list-sm>
        <v-form ref="jobRequestForm" v-model="valid">
          <v-layout row wrap>
            <v-flex xs12>
              <v-menu
                v-model="requestedDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="requestedDate"
                    label="Requested Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    :rules="[rules.required]"
                    v-on="on"
                    hint="Request a date you would like the inspection to take place"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="requestedDate"
                  @input="requestedDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                v-model="jobrequest.notes"
                label="Details"
                prepend-icon="mdi-notebook"
                :rules="[rules.required]"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="save()"
          :disabled="saveButtonDisabled"
          :loading="saving"
          >Save</v-btn
        >
        <v-btn text @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Amplify, { API } from "aws-amplify";
import moment from "moment";
export default {
  name: "RequestJobDialog",
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  methods: {
    request(detail, defaultStartDate) {
      this.detail = detail;
      this.updateNotes();
      this.showDialog = true;
      if (defaultStartDate) {
        this.requestedDate = moment(defaultStartDate).format("YYYY-MM-DD");
      } else {
        this.requestedDate = moment().format("YYYY-MM-DD");
      }
    },
    save() {
      if (this.valid) {
        // Convert dates from String to Epoc
        this.jobrequest.requestedDateFrom = moment
          .utc(this.requestedDate, "YYYY-MM-DD")
          .startOf("day")
          .valueOf();
        this.jobrequest.requestedDateTo = moment
          .utc(this.requestedDate, "YYYY-MM-DD")
          .endOf("day")
          .valueOf();
        this.jobrequest.requestType = this.detail.type;
        this.saving = true;
        API.post("sites", "/sites/" + this.property.id + "/jobrequests/", {
          body: this.jobrequest,
        }).then(() => {
          this.showDialog = false;
          this.$refs.jobRequestForm.reset();
          this.$emit("job-requested");
          this.saving = false;
        });
      }
    },
    cancel() {
      this.showDialog = false;
      this.$refs.jobRequestForm.reset();
      this.job = {};
    },
    updateNotes() {
      if (this.detail.type == "EICR") {
        this.jobrequest.notes = `
Please carry out an Electrical Installation Condition Report at ${this.property.address.line}.

Please contact my tenant ${this.property.contact} on ${this.property.phoneNumber} or ${this.property.email} to arrange a date, time and access to the property.
`;
      } else {
        this.jobrequest.notes = `
Please carry out a Gas Safety Check at ${this.property.address.line}.

Please contact my tenant ${this.property.contact} on ${this.property.phoneNumber} or ${this.property.email} to arrange a date, time and access to the property.
`;
      }
    },
    title() {
      if (!this.detail) return;
      if (this.detail.type === "EICR")
        return "Electrical Installation Condition Report";
      return "Request Landlord's gas safety Certificate";
    },
  },
  data: () => ({
    showDialog: false,
    valid: false,
    saving: false,
    requestedDateMenu: false,
    requestedDate: null,
    jobType: "",
    jobrequest: {},
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email.";
      },
      currency: (value) => {
        const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
        return pattern.test(value) || "Invalid currency amount.";
      },
    },
  }),
  computed: {
    saveButtonDisabled() {
      return !this.valid || this.saving;
    },
  },
};
</script>